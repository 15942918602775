import {memo, useState} from 'react';

import help from '../../../../assets/icon/help.svg';
import * as SC from './how-order.style';

export const HowOrder = memo(() => {

	const [open, setOpen] = useState(false);

const setOpenClick = ()=> setOpen(prevState => !prevState);

	return (
		<SC.HowOrderContainer>
			<SC.HowOrder onClick={setOpenClick}>
				<span>Как бронировать?</span>
				<img src={help} alt="help-icon"/>
			</SC.HowOrder>
			{open && <SC.List>
				<li>Настроить фильтры для поиска интересующей вас услуги.</li>
				<li>Выбрать из списка интересующую услугу.</li>
				<li>Нажать на изображение.</li>
				<li>Выбирайте интересующую вас дату и время.</li>
				<li>Подтвердите заказ, в ближайшее время с вами свяжется администратор для уточнения деталей</li>
			</SC.List>}
		</SC.HowOrderContainer>
	);
});
