import {createAsyncThunk} from '@reduxjs/toolkit';

import {registerAPI} from '../../../api/registration-api';
import {SystemID} from '../../../interfaces/interfaces';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';

export const fetchDeactivatePlatform = createAsyncThunk(
	'fetchDeactivatePlatform',
	async (data:SystemID, {rejectWithValue,dispatch}) => {
		try {
			const response = await registerAPI.deactivatePlatform(data);
			return response.data;
		} catch (error) {
			handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
