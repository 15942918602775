import {createAsyncThunk} from '@reduxjs/toolkit';

import {registerAPI} from '../../../api/registration-api';
import {OrganizationName} from '../../../interfaces/interfaces';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';


export const fetchRemoveOrg = createAsyncThunk(
    'fetchRemoveOrg',
    async (data:OrganizationName, {rejectWithValue,dispatch}) => {
        try {
            const response = await registerAPI.removeOrg(data);
            return response.data;
        } catch (error) {
				handleAxiosError({dispatch,rejectWithValue,error});
        }
    },
);
