import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	strokeColor?: string;
}

const IconRemove: FC<IconProps> = ({strokeColor, ...restProps}) => {
	return (
		<svg width="16" height="7" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
		<path id="Vector" d="M0.5 0.5L6.33 6.33M0.5 6.33L3.41 3.41L6.33 0.5" stroke="#FFFFFF" fill={strokeColor} />
		</svg>


);
};

IconRemove.defaultProps = {
	strokeColor: '#ffffff',
};

export default IconRemove;
