import styled from '@emotion/styled';

interface ContainerStyle {
	showButtonsControl?: boolean;
}

export const Container = styled.div<ContainerStyle>`
	display: flex;
	flex-direction: column;
	gap: 20px;
	border-radius: 8px;
	justify-content: space-between;
	padding: ${props => props.showButtonsControl ? '34px 20px 20px' : '20px'};
	background-color: var(--grey);
	color: var(--title);
`;
export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	justify-content: space-between;
	position: relative;
`;
export const Title = styled.h2`
	font-weight: 600;
	font-size: 20px;
	line-height: 1.3;
`;

export const Hr = styled.hr`
	width: 100%;
	background-color: #cfcfcf;
`;
export const Label = styled.span`
	font-weight: 300;
	font-size: 14px;
	line-height: 1.3;
	text-wrap: nowrap;
`;
export const DataContainer = styled.div`
	display: grid;
	justify-content: space-between;
	grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
	gap: 20px;
`;
export const HasAbonement = styled.div`
	font-weight: 700;
	font-size: 18px;
	line-height: 1.3;
`;
export const ListServices = styled.ul`
	font-weight: 400;
	font-size: 18px;
	line-height: 1.2;

	li {
		position: relative;
		padding-left: 20px;
	}

	li::before {
		font-size: 44px;
		content: '•';
		color: green;
		position: absolute;
		left: 0;
		top: 60%;
		transform: translateY(-50%);
	}
`;
export const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	justify-content: center;
`;
export const DetailsButton = styled.button`
	background-color: transparent;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.385;
	text-decoration: underline;
	text-decoration-skip-ink: none;
	text-align: center;
	color: var(--green);
	cursor: pointer;
`;
