import {FC, memo, ReactNode} from 'react';

import * as SC from './detail-wrapper.style';

interface DetailWrapperProps {
	text: string;
	icon: ReactNode;
	label: string
	unLimit?: boolean
}

export const DetailWrapper: FC<DetailWrapperProps> = memo(({text,icon, label, unLimit}) => {
	return (
		<SC.DataWrapper>
			<SC.Label>{label}</SC.Label>
			<SC.LocationWrapper>
				{icon}
				{!unLimit && <SC.DataText>{text}</SC.DataText>}
				{unLimit && <SC.UnlimitedText>{text}</SC.UnlimitedText>}
			</SC.LocationWrapper>
		</SC.DataWrapper>
	);
});

