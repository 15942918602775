import {createAsyncThunk} from '@reduxjs/toolkit';

import {crmPlatformAPI} from '../../../api/crm-platform';
import {DeleteAbonement} from '../../../interfaces/abonement';
import {emptyMessage} from '../../../shared/constants/message-user';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setIsShowMessage, setMessageUser, setShowLoader} from '../../reducers/user-data-reducer';


export const fetchRemoveAbonement = createAsyncThunk(
	'sportSlice/fetchRemoveAbonement',
	async (arg:DeleteAbonement, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await crmPlatformAPI.removeAbonement(arg);
			dispatch(setShowLoader({isShowLoader: false}));

			if (response.status === 200) {
				dispatch(setMessageUser({messageUser: emptyMessage,
					titleMessage: 'Новые данные успешно сохранены',
					descriptionMessage: 'Абонемент успешно удалён',
				}));
				dispatch(setIsShowMessage({isShowMessage: true}));
				return {status: response.status, data: response.data};
			}
		} catch (error) {
			handleAxiosError({dispatch,error,rejectWithValue});
		}
	},
);
