import styled from '@emotion/styled';

export const Container = styled.div`
	display: flex;
	gap: 6px;
	position: absolute;
	top: -24px;
	right: -12px;
`;
export const Button = {
	width: 'fit-content',
	height: '24px',
	padding: '6px 10px',
	fontWeight: 400,
	fontSize: '10px',
	letterSpacing: '0.02em',
	gap: '4px',
};
