import {FC, memo} from 'react';

import * as SC from './name.style';

interface NameProps{
	title:string
}

export const Name:FC<NameProps> = memo(({title}) => {
	return <SC.Name>{title}</SC.Name>;
});
