import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';

import {crmApi} from '../api-rtkq/crm';
import {searchApi} from '../api-rtkq/search';
import {userApi} from '../api-rtkq/user';
import {abonement} from './reducers/abonement-reducer';
import {dependency} from './reducers/dependency-reducer';
import {faqSlice} from './reducers/faq-reducer';
import {mapSlice} from './reducers/map-reducer';
import {orderAttachReducer} from './reducers/order-attach-reducer';
import {ordersSlice} from './reducers/orders-reducer';
import {organizationSlice} from './reducers/organizations-reducer';
import {OrgReducer} from './reducers/orgReducer/organizationsReducer';
import {fieldSlice} from './reducers/platforms-reducer';
import {resizeScreenSlice} from './reducers/resize-screen-reduser';
import {scheduleEditorSettings} from './reducers/schedule-editor-settings-daily-reducer';
import {servicePreview} from './reducers/service-preview-reduser';
import {serviceSlice} from './reducers/service-reduser';
import {serviceReservationModal} from './reducers/service-reservation-modal-reducer';
import {sportSlice} from './reducers/sport-reducer';
import {stats} from './reducers/stats-reduser';
import {userDataSlice} from './reducers/user-data-reducer';

export const RootStateReducer = combineReducers({
	resizeScreen: resizeScreenSlice.reducer,
	fieldsState: fieldSlice.reducer,
	userState: userDataSlice.reducer,
	organizationState: organizationSlice.reducer,
	ordersState: ordersSlice.reducer,
	sportState: sportSlice.reducer,
	mapState: mapSlice.reducer,
	scheduleEditorSettings,
	service: serviceSlice.reducer,
	orderAttach: orderAttachReducer,
	dependency,
	abonement,
	stats,
	serviceReservationModal,
	servicePreview,
	faq: faqSlice.reducer,
	OrgReducer,
	[searchApi.reducerPath]: searchApi.reducer,
	[userApi.reducerPath]: userApi.reducer,
	[crmApi.reducerPath]: crmApi.reducer,

});

export const store = configureStore({
	reducer: RootStateReducer,
	middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
			.concat(searchApi.middleware)
			.concat(userApi.middleware)
			.concat(crmApi.middleware),
});
export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line no-redeclare
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
