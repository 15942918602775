import {createAsyncThunk} from '@reduxjs/toolkit';
import {isAxiosError} from 'axios';

import {AllServiceTypeParams} from '../../../api/query-params';
import {SearchPlatformAPI} from '../../../api/search-platform-api';
import {IServiceType} from '../../../interfaces/interfaces';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setArrServiceTypeString, setServicesTypes} from '../../reducers/sport-reducer';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const fetchGetAllTypesServices = createAsyncThunk(
	'sportSlice/fetchGetAllTypesServices',
	async (params: AllServiceTypeParams | undefined, {dispatch, rejectWithValue}) => {
		const timeout = setTimeout(() => {
			dispatch(setShowLoader({isShowLoader: true}));
		}, 1000);
		try {
			const response = await SearchPlatformAPI.getAllTypesServices(params);
			if (response.status === 200) {
				dispatch(setServicesTypes({data: response.data.service_types}));
				const arrString = response.data.service_types.map((service: IServiceType) => service.info.service_type);
				dispatch(setArrServiceTypeString({data: arrString}));
				return {status: response.status, data: response.data.service_types};
			}
			if (response.status === 204) {
				dispatch(setServicesTypes({data: []}));
				return {status: response.status, data: []};
			}
		} catch (error) {
			if (isAxiosError(error)) {
				handleAxiosError({dispatch, error, rejectWithValue});
			}
		} finally {
			clearTimeout(timeout);
			dispatch(setShowLoader({isShowLoader: false}));
		}
	},
);
