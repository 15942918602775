import classNames from 'classnames';
import {range} from 'lodash';
import {FC, useCallback, useMemo, useState} from 'react';

import {IOption,TimeSelect} from '../../../../../../../../components';
import {ScheduleItem} from '../../../../../../../../interfaces/interfaces';
import {TimePointMeta} from '../../../../../../../../interfaces/order';
import {OrderStepPolicy} from '../../../../../../../../interfaces/service';
import {getTimeDisplayValue} from '../../../../../../../../shared/utils/time/get-time-display-value';
import {calculateStartAndEndPoints} from '../../../../../../utils/calculate-start-and-end-points';
import {getError, validateRange} from '../../utils/validate';
import {TimeScheduleSlotCell} from '../time-schedule/components/time-schedule-slot-cell/time-schedule-slot-cell';
import styles from './time-start-end-select.module.css';

interface TimeStartEndSelectProps {
	startValue: number;
	endValue: number;
	orderPolicy: OrderStepPolicy | null;
	workingTime: ScheduleItem[];
	defaultOption: IOption<number>;
	onChange?: (value: number) => void;
	onSubmit: (value: {start: number; end: number}) => void;
	containerClassName?: string;
	timeSelectClassName?: string;
	displayValueClassName?: string;
	pointsMetaMap?: Record<number, TimePointMeta>;
}

export const TimeStartEndSelect: FC<TimeStartEndSelectProps> = ({
	startValue,
	endValue,
	orderPolicy,
	defaultOption,
	workingTime,
	onChange,
	onSubmit,
	containerClassName,
	timeSelectClassName,
	displayValueClassName = styles.white_display,
	pointsMetaMap,
}) => {
	const isNotDefault = (value: number) => value && value !== defaultOption.value;
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [errorRange, setErrorRange] = useState<boolean>(false);

	const [start, setStart] = useState<number>(startValue);
	const [end, setEnd] = useState(endValue);

	const minStart = useMemo(() => Math.min(...workingTime.map((item) => item.start_working)), [workingTime]);
	const maxEnd = useMemo(() => Math.max(...workingTime.map((item) => item.end_working)), [workingTime]);

	const [startPoints, endPoints] = useMemo(() => {
		return calculateStartAndEndPoints(orderPolicy, workingTime, maxEnd, minStart, startValue);
	}, [orderPolicy, workingTime, maxEnd, minStart, startValue]);

	const handleOnChange = useCallback(({start, end}: {start: number; end: number}) => {
		if (!(isNotDefault(start) && isNotDefault(end))) {
			return;
		}

		if (orderPolicy) {

			const error = getError(start, end, orderPolicy);
			setErrorMessage(error);
			if (error) {
				return;
			}

			if (pointsMetaMap) {
				const validatedRange = validateRange({start, end, step: orderPolicy.service_step, pointsMetaMap});

				setErrorRange(!validatedRange.isValid);
				if (!validatedRange.isValid) {
					return;
				}
			}
		}
		onSubmit({
			start: start === defaultOption.value ? 0 : start,
			end: end === defaultOption.value ? 0 : end,
		});
	},[orderPolicy, pointsMetaMap, defaultOption]);

	return (
		<div className={styles.container}>
			<div className={classNames(styles.time_control, containerClassName)}>
				<TimeSelect
					className={classNames(styles.time_select_item, timeSelectClassName)}
					displayValueClassName={displayValueClassName}
					iconStrokeColor={'white'}
					label={'Начало'}
					value={start}
					onChange={(value) => {
						setStart(value);
						if (onChange) {
							onChange(value);
						}
						handleOnChange({start: value, end});
					}}
					defaultOption={defaultOption}
					points={startPoints}
				/>
				<TimeSelect
					className={classNames(styles.time_select_item, timeSelectClassName)}
					displayValueClassName={displayValueClassName}
					iconStrokeColor={'white'}
					label={'Конец'}
					value={end}
					onChange={(value) => {
						setEnd(value);
						if (onChange) {
							onChange(value);
						}
						handleOnChange({start, end: value});
					}}
					defaultOption={defaultOption}
					points={endPoints}
				/>
			</div>
			{errorMessage && (
				<div className={styles.error}>
					{' '}
					<p className={styles.error}>{errorMessage}</p>
				</div>
			)}

			{pointsMetaMap && errorRange && orderPolicy && (
				<div className={styles.error_range}>
					{range(start, end, orderPolicy.service_step).map((point) => {
						return (
							<TimeScheduleSlotCell
								className={styles.error_range_item}
								point={point}
								pointsMeta={pointsMetaMap[point]}
								key={point}
								active={true}
								value={getTimeDisplayValue(point, point + orderPolicy.service_step, true)}
							/>
						);
					})}
				</div>
			)}
		</div>
	);
};
