import {FC, memo} from 'react';

import {AbonementType} from '../../interfaces/abonement';
import {AbonementNew} from './abonement-new/abonement-new';
import * as SC from './abonements.style';

interface AbonementsInterface {
	abonements: AbonementType[];
	refetch?: () => void;
	showButtonsControl? : boolean;
}

export const Abonements: FC<AbonementsInterface> = memo(({abonements, refetch, showButtonsControl}) => {

	return (
		<SC.Container count={abonements.length}>
			{abonements.map(i => {
				return <AbonementNew key={i.id} abonement={i} refetch={refetch}
									 showButtonsControl={showButtonsControl}/>;
			})}
		</SC.Container>
	);
});
