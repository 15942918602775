import {createAsyncThunk} from '@reduxjs/toolkit';

import {serchStatsAPI} from '../../../api/search-stats';
import {Statistic} from '../../../interfaces/interfaces';
import {requestCodes} from '../../../shared/constants/request-codes';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const fetchStatistic = createAsyncThunk(
	'statsSlice/fetchStatistic',
	async (params: Statistic, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await serchStatsAPI.getStats(params);
			dispatch(setShowLoader({isShowLoader: false}));

			if (response.status === requestCodes.success) {
				return {status: response.status, data: response.data};
			}

			if (response.status === requestCodes.emptyData) {
				return {status: response.status, data: []};
			}
			return {status: response.status, data: response.data.services};
		} catch (error) {
			handleAxiosError({dispatch, rejectWithValue, error});
		}
	},
);
