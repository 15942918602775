import styled from '@emotion/styled';

export const Name = styled.h2`
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 52px;
	font-weight: 600;
	font-size: 20px;
	line-height: 1.3;
`;
