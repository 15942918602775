import styled from '@emotion/styled';

export const BtnWrapper = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 4px;
	height: 68px;
	justify-content: flex-end;
`;
export const Success = styled.div`
	color: var(--purple);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
	border-radius: 6px;
	border: 1px solid var(--purple);
	height: 32px;
	width: 100%;
`;
export const Closed = styled.div`
	border-radius: 5px;
	border: 1px solid #6F6F6F;
	height: 32px;
	padding: 6px 20px;
	background: #E2E2E2;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
	text-align: center;
	width: 100%;
	gap: 5px;
`;
export const Button = styled.button`
	border-radius: 4px;
	border: 1px solid var(--yellow);
	height: 32px;
	padding: 6px 20px;
	color: var(--black-color);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
	width: 100%;
	cursor: pointer;
`;
export const ConfirmtOrderButton = {
	padding: '14px 20px',
	fontSize: '14px',
	fontStyle: 'normal',
	fontWeight: 400,
	lineHeight: '130%',
};
