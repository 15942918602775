import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {AbonementInfo, AbonementType} from '../../interfaces/abonement';
import {PaymentMethod} from '../../interfaces/interfaces';
import {ServiceListItem} from '../../interfaces/service';
import {fetchGetListAbonements} from '../thunks/abonements/get-list-abonements';
import {fetchGetTotalListAbonements} from '../thunks/abonements/get-total-list-abonements';

interface InitialStateAbonement {
	abonements: AbonementType[];
	checkedAbonementUpdate: AbonementType;
	total: number;
}

const initialState: InitialStateAbonement = {
	abonements: [],
	checkedAbonementUpdate: {} as AbonementType,
	total: 0
};

export const abonementSlice = createSlice({
	name: 'abonement',
	initialState,
	reducers: {
		setCheckedAbonementUpdate: (state: InitialStateAbonement, action) => {
			state.checkedAbonementUpdate = action.payload;
		},
		setRefreshCheckedAbonementUpdate: (state: InitialStateAbonement, action: PayloadAction<{
			info: AbonementInfo;
			org_info: {
				org_name: string
				logo: string | null
			}
			platform_info: {
				city: string | null;
				location_info: { address: string; location_x: string; location_y: string };
				platform_id: string;
			};
			payment_method: PaymentMethod;
			services: ServiceListItem[]
		}>) => {
			state.checkedAbonementUpdate = {
				...state.checkedAbonementUpdate,
				info: action.payload.info,
				services: action.payload.services,
				platform_info: action.payload.platform_info,
				org_info: action.payload.org_info,
				payment_method: action.payload.payment_method
			};
		}
	},
	extraReducers: builder => {
		builder.addCase(fetchGetListAbonements.fulfilled, (state, action) => {
			state.abonements = action.payload?.data;
		});
		builder.addCase(fetchGetTotalListAbonements.fulfilled, (state, action) => {
			state.total = action.payload?.data;
		});
	},
});

export const abonement = abonementSlice.reducer;
export const {setCheckedAbonementUpdate, setRefreshCheckedAbonementUpdate} = abonementSlice.actions;
