import {createAsyncThunk} from '@reduxjs/toolkit';

import {crmPlatformAPI} from '../../../api/crm-platform';
import {GetAllModeratorParams} from '../../../api/query-params';
import {requestCodes} from '../../../shared/constants/request-codes';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setModeratorsOrg} from '../../reducers/organizations-reducer';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const fetchGetModeratorsByOrganization = createAsyncThunk(
	'organizationSlice/fetchGetModeratorsByOrganization',
	async (params: GetAllModeratorParams, {
		dispatch,
		rejectWithValue,
	}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await crmPlatformAPI.getModeratorsByOrganization(params);
			const responseTotalCount = await crmPlatformAPI.getModeratorsTotalCount(params);
			dispatch(setShowLoader({isShowLoader: false}));

			if (response.status === requestCodes.success && responseTotalCount.status === requestCodes.success) {
				dispatch(setModeratorsOrg({data: response.data.users, totalCount: responseTotalCount.data.total}));
				return {status: response.status, data: response.data.users, totalCount: responseTotalCount.data.total};
			}

			if (response.status === requestCodes.emptyData) {
				dispatch(setModeratorsOrg({data: []}));
				dispatch(setShowLoader({isShowLoader: false}));
				return {status: response.status, data: []};
			}
		} catch (error) {
				handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
