import {createAsyncThunk} from '@reduxjs/toolkit';

import {crmPlatformAPI} from '../../../api/crm-platform';
import {SystemID} from '../../../interfaces/interfaces';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';

export const fetchDeactivateService = createAsyncThunk(
	'fetchDeactivateService',
	async (data:SystemID, {rejectWithValue, dispatch}) => {
		try {
			const response = await crmPlatformAPI.deactivateService(data);
			return response.data;
		} catch (error) {
			handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
