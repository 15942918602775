import styled from '@emotion/styled';

export const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 10000;
	overflow: auto;
`;
export const Window = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	position: relative;
	max-height: 100vh;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: var(--white-color);
	border-radius: 6px;
	width: 530px;
	@media screen and (max-width: 560px) {
		width: 100%;
	}
`;
export const ContentWrapper = styled.div`
	overflow: auto;
	padding: 20px 16px;
`;

export const CloseBtn  = styled.button`
	position: absolute;
	width: 30px !important;
	height: 30px !important;
	padding: 4px;
	font-weight: 600;
	right: 10px;
	top: 10px;
	font-size: 1.4rem;
	color: var(--grey-color);
	border: 1px solid var(--grey-color);
	border-radius: 50%;
	cursor: pointer;
	transition: 0.7s ease-in-out;
	&:hover {
		color: var(--blue-color);
		border: 1px solid var(--blue-color);
	}
`;
