import {FC} from 'react';

import {ButtonCard} from '../buttons';
import * as SC from './confirm-deletion.style';

interface ConfirmDeletionProps {
	type: string;
	remove: boolean;
	deleteTrue: () => void;
	deleteFalse: () => void;
	style?: Record<string, string>
}

export const ConfirmDeletion: FC<ConfirmDeletionProps> = ({type, style, remove, deleteFalse, deleteTrue}) => {
	return (
		<SC.Container style={style} type={type === 'remove' || type === 'recover'} remove={remove}>
			<SC.RemoveTitle>Вы уверены?</SC.RemoveTitle>
			<SC.RemoveBtns>
				<ButtonCard buttonClick={deleteTrue} title={'Да'} background={'var(--yellow)'} />
				<ButtonCard buttonClick={deleteFalse} title={'Нет'} background={'var(--green)'} />
			</SC.RemoveBtns>
		</SC.Container>
	);
};
