import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ActivateOrderParams} from '../../api/query-params';
import {InfoCalendarRulesType, InfoOrdersPlatform, ScheduleInfo, UserServerData} from '../../interfaces/interfaces';
import {IOrder} from '../../interfaces/order';

export interface OrdersState {
	isShowOrderContainer: boolean;
	infoOrdersPlatform: InfoOrdersPlatform[];
	infoOrdersOrg: InfoOrdersPlatform[];
	infoUserByOrder: UserServerData[];
	infoOrdersUser: InfoOrdersPlatform[];
	infoOrdersUserByDay: IOrder[];
	infoOrdersFilter: InfoOrdersPlatform[];
	infoRules: ScheduleInfo[];
	infoRulesCalendar: InfoCalendarRulesType | null;
	infoRulesUpdate: ScheduleInfo | null
	orderActivate: null | ActivateOrderParams

	// from startPages
	checkedSport: string;
	checkedServiceType: string;
	checkedDate: string;
	checkedTimeStart: number;
	checkedDuration: number;
	checkedAmount: number;
}

const initialState: OrdersState = {
	isShowOrderContainer: false,
	infoOrdersPlatform: [],
	infoOrdersOrg: [],
	infoOrdersUser: [],
	infoUserByOrder: [],
	infoOrdersUserByDay: [],
	infoOrdersFilter: [],
	infoRules: [],
	infoRulesCalendar: null,
	orderActivate: null,
	infoRulesUpdate: null,

	// from startPages
	checkedSport: '',
	checkedServiceType: '',
	checkedDate: '',
	checkedTimeStart: 0,
	checkedDuration: 0,
	checkedAmount: 0,
};

export const ordersSlice = createSlice({
	name: 'ordersSlice',
	initialState,
	reducers: {
		setInfoOrdersFilter: (state: OrdersState, action) => {
			state.infoOrdersFilter = [...action.payload.data];
		},
		setInfoOrdersUserByDay: (state: OrdersState, action) => {
			const infoOrdersUserByDay = action.payload.data.filter((i: IOrder) => i.status_info.status !== 'Closed');
			state.infoOrdersUserByDay = [...infoOrdersUserByDay];
		},
		setInfoOrdersUser: (state: OrdersState, action) => {
			state.infoOrdersUser = [...action.payload.data];
		},
		setInfoUserByOrder: (state: OrdersState, action) => {
			state.infoUserByOrder = [...action.payload.data];
		},
		resetInfoOrdersUser: (state: OrdersState) => {
			state.infoOrdersUser = [];
			state.infoOrdersUserByDay = [];
		},
		resetInfoOrdersOrg: (state: OrdersState) => {
			state.infoOrdersOrg = [];
		},
		resetInfoOrdersPlatform: (state: OrdersState) => {
			state.infoOrdersPlatform = [];
		},
		resetInfoRulesUpdate: (state: OrdersState) => {
			state.infoRulesUpdate = null;
		},
		setIsShowOrderContainer: (state: OrdersState, action) => {
			state.isShowOrderContainer = action.payload.data;
		},
		setInfoRules: (state: OrdersState, action: PayloadAction<ScheduleInfo[]>) => {
			state.infoRules = [...state.infoRules, ...action.payload];
		},
		setInfoRulesData: (state: OrdersState, action: PayloadAction<ScheduleInfo[]>) => {
			state.infoRules = action.payload;
		},
		setDeleteInfoRules: (state: OrdersState, action: PayloadAction<number>) => {
			state.infoRules = state.infoRules.filter((_, i) => i !== action.payload);
		},
		setOrderActivate: (state: OrdersState, action) => {
			state.orderActivate = action.payload;
		},
		setUpdateInfoRules: (state: OrdersState, action: PayloadAction<number>) => {
			state.infoRulesUpdate = state.infoRules.filter((_, i) => i === action.payload)[0];
			state.infoRules = state.infoRules.filter((_, i) => i !== action.payload);
		},
	},
});

export const {
	setInfoOrdersFilter,
	setInfoOrdersUserByDay,
	resetInfoOrdersUser,
	setInfoOrdersUser,
	resetInfoOrdersPlatform,
	resetInfoOrdersOrg,
	setInfoUserByOrder,
	setIsShowOrderContainer,
	setInfoRules,
	setDeleteInfoRules,
	setInfoRulesData,
	setOrderActivate,
	setUpdateInfoRules,
	resetInfoRulesUpdate,
} = ordersSlice.actions;
