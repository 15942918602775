import {createAsyncThunk} from '@reduxjs/toolkit';

import {registerAPI} from '../../../api/registration-api';
import {OrganizationName} from '../../../interfaces/interfaces';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';


export const fetchRecoverOrg = createAsyncThunk(
	'fetchRecoverOrg',
	async (data: OrganizationName, {rejectWithValue, dispatch}) => {
		try {
			const response = await registerAPI.recoverOrg(data);
			return response.data;
		} catch (error) {
				handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
