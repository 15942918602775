import {FC} from 'react';

import {useLazyCloseOrderQuery} from '../../../../api-rtkq/crm';
import {OrderServerStatus} from '../../../../interfaces/enums';
import {IOrder} from '../../../../interfaces/order';
import {getIdUser} from '../../../../shared/utils/decoder-jwt';
import {renderStatus} from '../../../../shared/utils/order/order';
import {Button} from '../../button/button';
import * as SC from './order-card-btn-admin.style';

interface OrderCardAdminProps {
	order: IOrder;
	refetch: () => void;
	handleActivateOrder: () => void;
}

export const OrderCardBtnAdmin: FC<OrderCardAdminProps> = ({order, refetch, handleActivateOrder}) => {
	const userId = getIdUser();
	const [closeOrder] = useLazyCloseOrderQuery();

	const handleCancelOrder = async () => {
		const ownedBy = 'Registered' in order.order_details.owned_by ? order.order_details.owned_by.Registered.id : order.order_details.owned_by.Anonym.id;
		await closeOrder({
			owned_by: ownedBy,
			order_id: order.id,
			requester: userId,
			platform_id: order.host_info.platform_id,
			reason: '',
		});
		await refetch();
	};

	return (
		<SC.BtnWrapper>
			{order.status_info.status === OrderServerStatus.WaitApproval && <Button label={'Подтвердить заказ '} bgColor={'purple'} style={SC.ConfirmtOrderButton}
																					onClick={handleActivateOrder}/>}
			{order.status_info.status === OrderServerStatus.Success
				? <SC.Success>Заказ подтвержден</SC.Success>
				: ((order.status_info.status === OrderServerStatus.Closed || order.status_info.status === OrderServerStatus.Ignored)
					? <SC.Closed>Заказ {renderStatus(order).toLowerCase()}</SC.Closed> : <>
						{(order.status_info.status !== OrderServerStatus.IgnoredByPlatform)
							? <SC.Button onClick={handleCancelOrder}>Отменить заказ</SC.Button>
							: <Button label={'Подтвердить заказ '} bgColor={'purple'} style={SC.ConfirmtOrderButton}
									  onClick={handleActivateOrder}/>}
					</>)}
		</SC.BtnWrapper>
	);
};
