import classNames from 'classnames';
import {FC, useMemo} from 'react';

import {displayAddress} from '../../../../../interfaces/interfaces';
import {PlatformListItem} from '../../../../../interfaces/platform';
import {cleanWebsiteUrl, getUserNikFromInstUrl} from '../../../../../shared/utils/convert-url';
import {getPlatformWorkingTime} from '../../../../../shared/utils/get-platform-working-time';
import {extractUniqueTimePairs} from '../../../../../shared/utils/time/extract-unique-time-pairs';
import {SingleSelect} from '../../../../controls';
import {ListWithIcons} from '../../../../list-with-icons/list-with-icons';
import IconCall from '../../../../svg-icon-components/icon-call';
import IconInstagram from '../../../../svg-icon-components/icon-instagram';
import IconLocation from '../../../../svg-icon-components/icon-location';
import IconTelegram from '../../../../svg-icon-components/icon-telegram';
import IconTime from '../../../../svg-icon-components/icon-time';
import IconWebSite from '../../../../svg-icon-components/icon-web-site';
import styles from './platform-card-contacts-list.module.css';

interface PlatformCardContactsListProps {
	platform: PlatformListItem;
	className?: string;
	compact?: boolean;
}

export const PlatformCardContactsList: FC<PlatformCardContactsListProps> = ({
																				platform,
																				className,
																				compact = false,
																			}) => {
	const contacts = platform?.contacts;
	const day_info = platform.day_info;

	const time = useMemo(() => getPlatformWorkingTime(day_info), [day_info]);
	const uniqueTime = extractUniqueTimePairs([...new Set(time)]);

	return (
		<ListWithIcons
			className={classNames(styles.contacts_list, className, {[styles.contacts_list_compact]: compact})}>
			<ListWithIcons.Item icon={<IconLocation />} contentClassName={styles.list_item_content}>
				<p className={styles.contacts_list_item_label}>
					{displayAddress(platform.city, platform.location_info.address)}
				</p>
			</ListWithIcons.Item>
			<ListWithIcons.Item icon={<IconTime />} contentClassName={styles.list_item_content}>
				<div className={styles.contacts_list_item_select}>
					{uniqueTime.length === 1 || uniqueTime[0] === 'Выходной'
						? <span>{uniqueTime[0]}</span>
						: <SingleSelect
							containerClassName={styles.selector_container}
							options={uniqueTime.map((time) => ({
								value: time,
								label: time,
							}))}
							value={uniqueTime[0]}
						/>
					}
					<span className={styles.contacts_list_item_content}>Сегодня</span>
				</div>
			</ListWithIcons.Item>
			{contacts.viber ? (
				<ListWithIcons.Item icon={<IconCall />} contentClassName={styles.contacts_list_item_content}>
					<span className={styles.contacts_list_item_label}>{contacts.viber}</span>
				</ListWithIcons.Item>
			) : null}
			{contacts.instagram ? (
				<ListWithIcons.Item icon={<IconInstagram />} contentClassName={styles.contacts_list_item_content}>
					<a href={contacts.instagram} className={classNames(styles.contacts_list_item_label, styles.link)}
					   target="_blank">
						{getUserNikFromInstUrl(contacts.instagram)}
					</a>
				</ListWithIcons.Item>
			) : null}
			{contacts.telegram ? (
				<ListWithIcons.Item icon={<IconTelegram />} contentClassName={styles.contacts_list_item_content}>
					<a href={contacts.telegram} className={classNames(styles.contacts_list_item_label, styles.link)}
					   target="_blank">
						@{contacts.telegram.split('/').pop()}
					</a>
				</ListWithIcons.Item>
			) : null}
			{contacts.web_site ? (
				<ListWithIcons.Item icon={<IconWebSite />} contentClassName={styles.contacts_list_item_content}>
					<a href={contacts.web_site} className={classNames(styles.contacts_list_item_label, styles.link)}
					   target="_blank">
						{cleanWebsiteUrl(contacts.web_site)}
					</a>
				</ListWithIcons.Item>
			) : null}
		</ListWithIcons>
	);
};
