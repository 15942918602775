import {OrderServerStatus, OrderStatus} from '../../../interfaces/enums';
import {IOrder} from '../../../interfaces/order';

export const renderStatus = (order: IOrder): string => {
	if (typeof order.status_info.status === 'string') {
		return OrderStatus[order.status_info.status as keyof typeof OrderStatus];
	}
	return OrderStatus.Closed;
};

export const getStatusIcon = (canceled_icon: string, confirmed_icon: string, pending_icon: string, status: string | { Closed: { reason?: string } }) => {
	if (status === OrderServerStatus.Closed || status === OrderServerStatus.Ignored || status === OrderServerStatus.IgnoredByPlatform) {
		return canceled_icon;
	} else if (status === OrderServerStatus.Pending || status === OrderServerStatus.WaitApproval || status === OrderServerStatus.Process) {
		return pending_icon;
	}
	return confirmed_icon;
};

export const getStatusColor = (status: string | { Closed: { reason?: string } }) => {
	if (status === OrderServerStatus.Closed || status === OrderServerStatus.Ignored || status === OrderServerStatus.IgnoredByPlatform) {
		return 'var(--yellow)';
	} else if (status === OrderServerStatus.Pending || status === OrderServerStatus.WaitApproval || status === OrderServerStatus.Process) {
		return 'var(--green)';
	}
	return 'var(--purple)';
};

export const renderRequester = (order: IOrder, idUser: string) => {
	if (order.status_info.last_updated == null) {
		return 'Не было';
	}
	return order.status_info.requester === idUser ? 'Пользователь' : 'Площадка';
};
