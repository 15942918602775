import styled from '@emotion/styled';

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	padding: 12px;
	border-radius: 12px;
	background: #f7f7f7;
	cursor: pointer;
	max-width: 290px;
`;
export const Vector = styled.img`
	width: 10px;
	transform: rotateY(180deg);
	filter: invert(35%) sepia(83%) saturate(399%) hue-rotate(144deg) brightness(64%) contrast(89%);
`;
export const Logo = styled.img`
	width: 40px;
	height: 40px;
	border-radius: 50%;
`;
export const ContentWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;
