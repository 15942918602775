import {createAsyncThunk} from '@reduxjs/toolkit';

import {GetOrganizationsByFiltersParams} from '../../../api/query-params';
import {SearchPlatformAPI} from '../../../api/search-platform-api';
import {requestCodes} from '../../../shared/constants/request-codes';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setOrganizations} from '../../reducers/organizations-reducer';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const fetchGetOrganizationsByFilter = createAsyncThunk(
	'organizationSlice/fetchGetOrganizationsByFilter',
	async (params: GetOrganizationsByFiltersParams, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await SearchPlatformAPI.getOrganizationsByFilter(params);

			if (response.status === requestCodes.success) {
				dispatch(setOrganizations({data: response.data.orgs}));
				return response.data.orgs;
			}

			if (response.status === requestCodes.emptyData) {
				dispatch(setOrganizations({data: []}));
				return {status: response.status, data: []};
			}
		} catch (error) {
				handleAxiosError({dispatch,rejectWithValue,error});
		} finally {
			dispatch(setShowLoader({isShowLoader: false}));
		}
	},
);
