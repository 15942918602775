import {useCallback, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {NavLink} from 'react-router-dom';

import {ReactComponent as IconLogin} from '../../../../assets/icon/login.svg';
import {ReactComponent as IconLogout} from '../../../../assets/icon/logout.svg';
import {ReactComponent as IconOrder} from '../../../../assets/icon/order.svg';
import {ReactComponent as IconProfile} from '../../../../assets/icon/profile.svg';
import {ReactComponent as IconRegistration} from '../../../../assets/icon/registration.svg';
import {AuthPages} from '../../../../interfaces/interfaces';
import {
	resetInfoOrdersOrg,
	resetInfoOrdersPlatform,
	resetInfoOrdersUser,
} from '../../../../redux/reducers/orders-reducer';
import {
	resetDataUser, setIsLoginPage,
	setIsShowRegOrAuth,
	setResetIsAuth,
	setStepRegistration1,
} from '../../../../redux/reducers/user-data-reducer';
import {useAppSelector} from '../../../../redux/selectors/hook';
import {
	isAuthSelector,
	nickUserSelector,
	selectedAdminFullRole,
} from '../../../../redux/selectors/user-state-selectors';
import {LOCAL_STORAGE_KEY_MAP} from '../../../../shared/constants/local-storage';
import {AUTH, MAIN, PROFILE_USER} from '../../../../shared/constants/path';
import {useClickOutside} from '../../../../shared/hooks/use-click-outside';
import {buildAdminMenu} from '../../../../shared/utils/admin/admin-menu';
import {isRightTokenTime} from '../../../../shared/utils/decoder-jwt';
import {getLocalStorage, isAuth} from '../../../../shared/utils/save-local-storage';
import styles from './user-info.module.css';

export const UserInfo = () => {
	const [isOpen, setIsOpen] = useState(false);

	const elementRef = useRef<HTMLDivElement | null>(null);
	useClickOutside(elementRef, () => setIsOpen(false));
	const userNick = useAppSelector(nickUserSelector);
	const isUserAuthenticatedByStore = useAppSelector(isAuthSelector);
	const isUserAuthenticated = isUserAuthenticatedByStore || isAuth();
	const selectedRole = useAppSelector(selectedAdminFullRole);
	const adminLink = buildAdminMenu(selectedRole)[0]?.to;

	const userName = userNick || getLocalStorage(LOCAL_STORAGE_KEY_MAP.userNick) || 'John Doe';

	const dispatch = useDispatch();

	const handleGoToPage = useCallback(() => {
		setIsOpen(false);
	}, []);

	const handleGoToLogout = useCallback(() => {
		localStorage.clear();
		dispatch(resetInfoOrdersUser());
		dispatch(resetDataUser());
		dispatch(setResetIsAuth());
		dispatch(resetInfoOrdersPlatform());
		dispatch(resetInfoOrdersOrg());
		dispatch(setIsLoginPage(true));
		handleGoToPage();
	}, []);

	const handleGoToRegistration = useCallback(() => {
		dispatch(setStepRegistration1());
		dispatch(setIsShowRegOrAuth({type: false}));
		dispatch(setIsLoginPage(false));
		handleGoToPage();
	}, []);

	const handleGoToLogin = useCallback(() => {
		dispatch(setStepRegistration1());
		dispatch(setIsShowRegOrAuth({type: true}));
		handleGoToPage();
	}, []);
	const isNotTokenTimeHandleGoToLogin = () => {
		handleGoToLogout();
		handleGoToLogin();
	};

	const isNotTokenTimeHandleGoToRegistration = () => {
		handleGoToLogout();
		handleGoToRegistration();
	};

	return (
		<div className={styles.user_info} ref={elementRef}>
			{isAuth()
				? (!isRightTokenTime()
					? <IconLogout onClick={() => setIsOpen((isOpen) => !isOpen)}/>
					: <IconProfile width={24} height={24} onClick={() => setIsOpen((isOpen) => !isOpen)}/>)
				: <IconProfile width={24} height={24} onClick={() => setIsOpen((isOpen) => !isOpen)}/>}
			{isOpen ? (
				<div className={styles.user_info_details}>
					{isUserAuthenticated && isRightTokenTime() ? (
						<>
							<div className={styles.user_info_details_list_item}>
								{adminLink ? (
									<NavLink
										to={adminLink}
										className={styles.user_info_details_list_item}
										onClick={handleGoToPage}
									>
										<IconProfile width={24} height={24}/>
									</NavLink>
								) : (<IconProfile width={24} height={24}/>)}
								{userName}
							</div>

							<NavLink
								to={PROFILE_USER}
								className={styles.user_info_details_list_item}
								onClick={handleGoToPage}
							>
								<IconOrder/>
								История заказов
							</NavLink>
							<NavLink
								to={MAIN}
								className={styles.user_info_details_list_item}
								onClick={handleGoToLogout}
							>
								<IconLogout/>
								Выйти
							</NavLink>
						</>
					) : (
						<>
							<NavLink
								to={AUTH}
								className={styles.user_info_details_list_item}
								onClick={isRightTokenTime() ? handleGoToLogin : isNotTokenTimeHandleGoToLogin}
								state={{page: AuthPages.login}}
							>
								<IconLogin/>
								Войти
							</NavLink>
							<NavLink
								to={AUTH}
								className={styles.user_info_details_list_item}
								onClick={isRightTokenTime() ? handleGoToRegistration : isNotTokenTimeHandleGoToRegistration}
								state={{page: AuthPages.registration}}
							>
								<IconRegistration/>
								Регистрация
							</NavLink>
						</>
					)}
				</div>
			) : null}
		</div>
	);
};
