export const cleanWebsiteUrl = (url: string) => {
	return url.replace(/^https?:\/\//, '').replace(/\/$/, '');
};

export const getUserNikFromInstUrl = (url: string) => {
	const splitUrl = url.split('/');
	if (splitUrl.at(-1) === '') {
		return `@${splitUrl.at(-2)}`;
	}
	return `@${splitUrl.at(-1)}`;
};
