import {range} from 'lodash';

import {OrderStepPolicy} from '../../../interfaces/service';

export const calculateStartAndEndPoints = (
	orderPolicy: OrderStepPolicy | null,
	workingTime: { start_working: number; end_working: number }[],
	maxEnd: number,
	minStart: number,
	start: number
): [number[], number[]] => {
	let startPoints: number[] = [];
	let endPoints: number[] = [];

	if (orderPolicy === null) {
		for (const item of workingTime) {
			startPoints.push(item.start_working);
			endPoints.push(item.end_working);
		}
		return [startPoints, endPoints];
	}

	const points = workingTime
		.map((item) => range(item.start_working, item.end_working, orderPolicy.service_step))
		.flat()
		.sort()
		.filter((point) => point + (orderPolicy.min_order_duration || 0) <= maxEnd);

	startPoints = points.filter((point) => point < maxEnd - (orderPolicy.min_order_duration || 0));
	endPoints = points.filter((point) => point >= Math.max(minStart, start) + (orderPolicy.min_order_duration || 0));

	return [startPoints, endPoints];
};
