import styled from '@emotion/styled';

export const ButtonGroup = styled.div`
	position: absolute;
	bottom: 10px;
	right: 16px;
	display: flex;
	gap: 10px;
`;
export const ButtonWrapper = styled.div`
  position: relative;
`;
export const ConfirmDeletion = {
	top: '-90px',
};
