import classNames from 'classnames';

import {arrStartPageCard_Order} from '../../../shared/constants/item-start-page';
import {FormQuickOrder} from '../../forms/form-quick-order';
import {Section} from '../../section/section';
import styles from './section-order.module.css';

export const SectionOrder = () => {
	return (
		<Section className={styles.section_order}>
			<h2 className='visually-hidden'>Форма быстрой брони</h2>
			<div className={styles.container_title}>
				<p className={classNames('title_section', 'title_section_white', styles.title)}>
					Почему стоит бронировать <br />
					на сайте&nbsp;<strong className="title_section_green">Sporder.by</strong> ?
				</p>
			</div>

			<div className={styles.container_form}>
				<FormQuickOrder />
			</div>

			<div className={styles.container_card}>
				{arrStartPageCard_Order.map((item, idx) => (
					<div className={styles.card} key={idx}>
						<h3 className={`${styles.card_title} ${idx === 0 ? styles.black_text : styles.white_text}`}>
							{item.title}
						</h3>
						<p className={`${styles.card_text} ${idx === 0 ? styles.black_text : styles.white_text}`}>
							{item.text}
						</p>
					</div>
				))}
			</div>
		</Section>
	);
};
