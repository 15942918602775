import {createAsyncThunk} from '@reduxjs/toolkit';

import {GetPlatformsByFilterParams} from '../../../api/query-params';
import {SearchPlatformAPI} from '../../../api/search-platform-api';
import {messageErrDataRecover} from '../../../shared/constants/message-user';
import {requestCodes} from '../../../shared/constants/request-codes';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setAllPlatforms} from '../../reducers/platforms-reducer';
import {setIsShowMessage, setMessageUser, setShowLoader} from '../../reducers/user-data-reducer';

export const fetchGetPlatformsByFilter = createAsyncThunk(
	'fieldSlice/fetchGetPlatformsByFilter',
	async (params: GetPlatformsByFilterParams | undefined, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await SearchPlatformAPI.getPlatformsByFilter(params);
			dispatch(setShowLoader({isShowLoader: false}));
			if (response.status === requestCodes.success) {
				dispatch(setAllPlatforms({data: response.data.platforms}));
				return {status: response.status, data: response.data.platforms};
			}

			if (response.status === requestCodes.emptyData) {
				dispatch(setAllPlatforms({data: []}));
				dispatch(setShowLoader({isShowLoader: false}));
				dispatch(setMessageUser({messageUser: messageErrDataRecover, textMessage: response.data.message}));
				dispatch(setIsShowMessage({isShowMessage: true}));
				return {status: response.status, data: []};
			}
		} catch (error) {
				handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
