import {createAsyncThunk} from '@reduxjs/toolkit';

import {registerAPI} from '../../api/registration-api';
import {SystemID} from '../../interfaces/interfaces';
import {handleAxiosError} from '../../shared/utils/handle-axios-error';


export const fetchRemovePlatform = createAsyncThunk(
    'fetchRemovePlatform',
    async (data:SystemID, {rejectWithValue, dispatch}) => {
        try {
            const response = await registerAPI.removePlatform(data);
            return response.data;
        } catch (error) {
				handleAxiosError({dispatch,rejectWithValue,error});
        }
    },
);
