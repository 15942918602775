import {lazy} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';

import Layout from './layout/layout';
import {
	ABONEMENT, ABOUT_PROJECT, ADMIN, ADMIN_PAGE, ADMIN_SETTING_GLOBAL, ADMIN_SETTING_ORDERS,
	ADMIN_SETTING_ORDERS_TYPE_PAGE, ADMIN_SETTING_ORGS, ADMIN_SETTING_PLATFORMS, ADMIN_SETTING_SERVICES,
	ADMIN_SETTING_USERS, ALL_ABONEMENTS, ALL_DERS_PAGE, ALL_ORG_PAGES, AUTH, CONTACTS, CREATE_ABONEMENT_PAGE,
	CREATE_DERS_PAGE, CREATE_ORG, CREATE_PLATFORM, CREATE_SERVICE, DOCS, ERROR_404, MAIN, ONE_FIELDS_PAGE,
	ONE_ORG_NAME_PAGES, ORDER_ACCEPT, ORDER_PAGE, PAYMENT_METHODS, PERSONAL_DATA_PROCESSING_POLICY,
	PLAY_FIELDS_PAGES, PROFILE_USER_PAGE, PROFILE_USER_SETTING_PAGE, PUBLIC_CONTRACT_RUMIKI_INDIVIDUALS,
	PUBLIC_CONTRACT_RUMIKI_LEDAL_ENTITIES, QUOTE_PAGE, REG, RESET_PASSWORD, SERVICE, SERVICE_ORDER, SERVICE_PAGE,
	SERVICE_PREVIEW, SETTING_CITIES, SETTING_SPORTS, SHOW_ALL_BLACKLISTED_PAGE, SHOW_ALL_EVENTS_PAGE,
	SHOW_ALL_INVENTORIES_PAGE, SHOW_ALL_MODERATORS_PAGE, SHOW_ALL_ORGS_PAGE, SHOW_ALL_PLATFORMS_PAGE,
	SHOW_ALL_TOURNAMENT_PAGE, SHOW_ALL_USERS_PAGE, SHOW_ALL_WORKOUT_PAGE, SHOW_RENT_PAGE, START_PAGES, STATISTIC,
	UPDATE_ABONEMENT_PAGE, UPDATE_ORG, UPDATE_PLATFORM, UPDATE_SERVICE,
} from './shared/constants/path';

const PersonalDataPolicy = lazy(() => import('./pages/docx-card/docx/personal-data-policy'));
const PublicAgreementRumiki = lazy(() => import('./pages/docx-card/docx/public-agreement-rumiki'));
const RumikiAgreementLegalEntities = lazy(() => import('./pages/docx-card/docx/rumiki-agreement-legal-entities'));
const DocxCards = lazy(() => import('./pages/docx-card/docx-card'));
const UpdateAbonement = lazy(() => import('./pages/abonements/update-abonement/update-abonement'));
const CreateAbonement = lazy(() => import('./pages/abonements/create-abonement/create-abonement'));
const AbonementsPage = lazy(() => import('./pages/abonements-page/abonements-page'));
const AdminPageSetting = lazy(() => import('./pages/admin-page-setting/admin-page-setting'));
const AllAbonements = lazy(() => import('./pages/abonements/all-abonements/all-abonements'));
const PaymentMethods = lazy(() => import('./pages/payment-methods/payment-methods'));
const PlatformListPage = lazy(() => import('./pages/platform-list-page/platform-list-page'));
const NotFoundPage = lazy(() => import('./pages/not-found-page/not-found-page'));
const ContactsPage = lazy(() => import('./pages/contacts-page/contacts-page'));
const ConfirmationOrder = lazy(() => import('./pages/confirmation-order/confirmation-order'));
const AboutProjectNew = lazy(() => import('./pages/about-project-new/about-project-new'));
const OrganizationListPage = lazy(() => import('./pages/organization-list-page/organization-list-page'));
const OrganizationPage = lazy(() => import('./pages/organization-page/organization-page'));
const PageAuth = lazy(() => import('./pages/page-auth/page-auth'));
const ProfileUserPage = lazy(() => import('./pages/profile-user-page/profile-user-page'));
const QuotePage = lazy(() => import('./pages/quote-page/quote-page'));
const PageRecoverPassword = lazy(() => import('./pages/recover-password-page/recover-password-page'));
const ServiceListPage = lazy(() => import('./pages/service-list-page/service-list-page'));
const ServicePage = lazy(() => import('./pages/service_page/service_page'));
const StartPages = lazy(() => import('./pages/start-pages/start-page'));
const Registration = lazy(() => import('./pages/registration/registration'));
const AdminPage = lazy(() => import('./pages/admin-page/admin-page'));
const OrderPage = lazy(() => import('./pages/order-page/order-page'));
const PlatformPage = lazy(() => import('./pages/platform-page/platform-page'));
const ServicePreview = lazy(() => import('./components/admin-page-containers/service-preview/service-preview'));
const AdminSettingsOrders = lazy(() => import('./components/admin-page-containers/setting-orders-container/admin-settings-orders'));
const ShowAllPlatforms = lazy(() => import('./components/admin-page-containers/setting-platform-container/show-all-platforms/show-all-platforms'));
const CreatePlatform = lazy(() => import('./components/admin-page-containers/setting-platform-container/setting-platform/create-platform/create-platform'));
const UpdatePlatform = lazy(() => import('./components/admin-page-containers/setting-platform-container/setting-platform/update-platform/update-platform'));
const AdminSettingsPlatform = lazy(() => import('./components/admin-page-containers/setting-platform-container/admin-settings-platform'));
const CreateOrg = lazy(() => import('./components/admin-page-containers/setting-organizations-container/setting-organizations/create-org/create-org'));
const ShowAllOrgs = lazy(() => import('./components/admin-page-containers/setting-organizations-container/show-all-orgs/show-all-orgs'));
const UpdateOrg = lazy(() => import('./components/admin-page-containers/setting-organizations-container/setting-organizations/update-org/update-org'));
const AdminSettingsOrgs = lazy(() => import('./components/admin-page-containers/setting-organizations-container/admin-settings-org'));
const ShowAllBlacklisted = lazy(() => import('./components/admin-page-containers/setting-users-container/show-all-pages/show-all-blacklisted/show-all-blacklisted'));
const ShowAllModerators = lazy(() => import('./components/admin-page-containers/setting-users-container/show-all-pages/show-all-moderators/show-all-moderators'));
const ShowAllUsers = lazy(() => import('./components/admin-page-containers/setting-users-container/show-all-pages/show-all-users/all-users'));
const ProfileUserSettingPage = lazy(() => import('./components/admin-page-containers/setting-users-container/components/profile-user-setting/profile-user-setting'));
const AdminSettingsUsers = lazy(() => import('./components/admin-page-containers/setting-users-container/admin-settings-users'));
const CreateService = lazy(() => import('./components/admin-page-containers/setting-services-container/create-service/create-service'));
const CreateDependency = lazy(() => import('./components/admin-page-containers/setting-services-container/dependency/create-dependency/create-dependency'));
const AllPageDependency = lazy(() => import('./components/admin-page-containers/setting-services-container/dependency/all-page-dependency/all-page-dependency'));
const ShowAllEvents = lazy(() => import('./components/admin-page-containers/setting-services-container/show-all-events/show-all-events'));
const ShowAllInventories = lazy(() => import('./components/admin-page-containers/setting-services-container/show-all-inventories/show-all-inventories'));
const ShowAllTournaments = lazy(() => import('./components/admin-page-containers/setting-services-container/show-all-tournaments/show-all-tournaments'));
const ShowAllWorkout = lazy(() => import('./components/admin-page-containers/setting-services-container/show-all-workout/show-all-workout'));
const ShowRent = lazy(() => import('./components/admin-page-containers/setting-services-container/show-rent/show-rent'));
const UpdateService = lazy(() => import('./components/admin-page-containers/setting-services-container/update-service/update-service'));
const AdminSettingsServices = lazy(() => import('./components/admin-page-containers/setting-services-container/admin-settings-services'));
const SettingCities = lazy(() => import('./components/admin-page-containers/setting-global-container/setting-cities/setting-cities'));
const AdminSettingsGlobal = lazy(() => import('./components/admin-page-containers/setting-global-container/admin-settings-global'));
const SettingSports = lazy(() => import('./components/admin-page-containers/setting-global-container/setting-sports/setting-sports'));
const Statistic = lazy(() => import('./pages/statistic/statistic'));

export const AppRouter = () => (
	<BrowserRouter>
		<Routes>
			<Route element={<Layout/>}>
				<Route path={DOCS} element={<DocxCards/>}/>
				<Route path={PERSONAL_DATA_PROCESSING_POLICY} element={<PersonalDataPolicy/>}/>
				<Route path={PUBLIC_CONTRACT_RUMIKI_INDIVIDUALS} element={<PublicAgreementRumiki/>}/>
				<Route path={PUBLIC_CONTRACT_RUMIKI_LEDAL_ENTITIES} element={<RumikiAgreementLegalEntities/>}/>
				<Route path={MAIN} element={<Navigate to={START_PAGES}/>}/>
				<Route path={START_PAGES} element={<StartPages/>}/>
				<Route path={ALL_ORG_PAGES} element={<OrganizationListPage/>}/>
				<Route path={ONE_ORG_NAME_PAGES} element={<OrganizationPage/>}/>
				<Route path={ORDER_ACCEPT} element={<ConfirmationOrder/>}/>
				<Route path={PLAY_FIELDS_PAGES} element={<PlatformListPage/>}/>
				<Route path={ONE_FIELDS_PAGE} element={<PlatformPage/>}/>
				<Route path={AUTH} element={<PageAuth/>}/>
				<Route path={REG} element={<Registration/>}/>
				<Route path={ABOUT_PROJECT} element={<AboutProjectNew/>}/>
				<Route path={CONTACTS} element={<ContactsPage/>}/>
				<Route path={ORDER_PAGE} element={<OrderPage/>}/>
				<Route path={ABONEMENT} element={<AbonementsPage/>}/>
				<Route path={QUOTE_PAGE} element={<QuotePage/>}/>
				<Route path={SERVICE_ORDER} element={<OrderPage/>}/>
				<Route path={SERVICE} element={<ServiceListPage/>}/>
				<Route path={SERVICE_PAGE} element={<ServicePage/>}/>
				<Route path={RESET_PASSWORD} element={<PageRecoverPassword/>}/>
				<Route path={PROFILE_USER_PAGE} element={<ProfileUserPage/>}/>
				<Route path={PAYMENT_METHODS} element={<PaymentMethods/>}/>
				{/* <Route path={FAQ} element={<Faq/>} />*/}
				<Route path={PROFILE_USER_SETTING_PAGE} element={<ProfileUserSettingPage/>}/>
				<Route path={ADMIN} element={<AdminPage/>}>
					<Route path={`${ADMIN_SETTING_GLOBAL}`} element={<AdminSettingsGlobal/>}>
						<Route path={SETTING_CITIES} element={<SettingCities/>}/>
						<Route path={ADMIN_PAGE} element={<AdminPageSetting/>}/>
						<Route path={STATISTIC} element={<Statistic/>}/>
						<Route path={SETTING_SPORTS} element={<SettingSports/>}/>
					</Route>
					<Route path={ADMIN_SETTING_ORGS} element={<AdminSettingsOrgs/>}>
						<Route path={CREATE_ORG} element={<CreateOrg/>}/>
						<Route path={UPDATE_ORG} element={<UpdateOrg/>}/>
						<Route path={SHOW_ALL_ORGS_PAGE} element={<ShowAllOrgs/>}/>
					</Route>
					<Route path={ADMIN_SETTING_PLATFORMS} element={<AdminSettingsPlatform/>}>
						<Route path={CREATE_PLATFORM} element={<CreatePlatform/>}/>
						<Route path={UPDATE_PLATFORM} element={<UpdatePlatform/>}/>
						<Route path={SHOW_ALL_PLATFORMS_PAGE} element={<ShowAllPlatforms/>}/>
						<Route path={CREATE_ABONEMENT_PAGE} element={<CreateAbonement/>}/>
						<Route path={ALL_ABONEMENTS} element={<AllAbonements/>}/>
						<Route path={UPDATE_ABONEMENT_PAGE} element={<UpdateAbonement/>}/>
					</Route>
					<Route path={ADMIN_SETTING_SERVICES} element={<AdminSettingsServices/>}>
						<Route path={CREATE_SERVICE} element={<CreateService/>}/>
						<Route path={UPDATE_SERVICE} element={<UpdateService/>}/>
						<Route path={ALL_DERS_PAGE} element={<AllPageDependency/>}/>
						<Route path={CREATE_DERS_PAGE} element={<CreateDependency/>}/>
						<Route path={SHOW_ALL_WORKOUT_PAGE} element={<ShowAllWorkout/>}/>
						<Route path={SHOW_ALL_INVENTORIES_PAGE} element={<ShowAllInventories/>}/>
						<Route path={SHOW_RENT_PAGE} element={<ShowRent/>}/>
						<Route path={SHOW_ALL_TOURNAMENT_PAGE} element={<ShowAllTournaments/>}/>
						<Route path={SHOW_ALL_EVENTS_PAGE} element={<ShowAllEvents/>}/>
					</Route>
					<Route path={ADMIN_SETTING_USERS} element={<AdminSettingsUsers/>}>
						<Route path={SHOW_ALL_USERS_PAGE} element={<ShowAllUsers/>}/>
						<Route path={SHOW_ALL_MODERATORS_PAGE} element={<ShowAllModerators/>}/>
						<Route path={SHOW_ALL_BLACKLISTED_PAGE} element={<ShowAllBlacklisted/>}/>
					</Route>
					<Route path={ADMIN_SETTING_ORDERS} element={<AdminSettingsOrders/>}/>
					<Route path={ADMIN_SETTING_ORDERS_TYPE_PAGE} element={<AdminSettingsOrders/>}/>
				</Route>
				<Route path={SERVICE_PREVIEW} element={<ServicePreview/>}/>
			</Route>
			<Route path={ERROR_404} element={<NotFoundPage/>}/>
		</Routes>
	</BrowserRouter>
);
