import {FC, memo} from 'react';

import no_image from '../../../../../assets/img/no_image.png';
import * as SC from './org-info.style';

interface OrgInfoProps{
	logo: string | null;
	address: string
	orgName: string
}

export const OrgInfo:FC<OrgInfoProps> = memo(({orgName, address,logo}) => {
	return (
		<SC.OrgInfoWrapper>
			<SC.LogoOrg src={logo || no_image} alt={orgName}/>
			<SC.OrgNameLocationWrapper>
				<SC.OrgName>{orgName}</SC.OrgName>
				<SC.Adress>{address}</SC.Adress>
			</SC.OrgNameLocationWrapper>
		</SC.OrgInfoWrapper>
	);
});
