import {FC, useCallback, useState} from 'react';

import {ServiceListItem} from '../../../interfaces/service';
import {useAppDispatch} from '../../../redux/store';
import {fetchRecoverService} from '../../../redux/thunks/recover-service';
import {fetchDeactivateService} from '../../../redux/thunks/services/deactivate-service';
import {fetchRemoveService} from '../../../redux/thunks/services/remove-service';
import {ConfirmDeletion} from '../../confirm-deletion/confirm-deletion';
import {ButtonCard} from '../button-card/button-card';
import * as SC from './button-group-setting-service.style';

interface ButtonGroupSettingServiceProps {
	updateCard: () => void;
	service: ServiceListItem;
	onUpdate: () => void;
}

export const ButtonGroupSettingService: FC<ButtonGroupSettingServiceProps> = ({
																				  service,
																				  updateCard,
																				  onUpdate,
																			  }) => {
	const [type, setType] = useState('');
	const dispatch = useAppDispatch();

	const warningDeleteService = () => setType('remove');
	const warningRecoverService = () => setType('recover');
	const deleteService = () => {
		dispatch(fetchRemoveService({id: service.id})).then(() => onUpdate());
		setType('');
	};
	const deactivateItem = useCallback(async () => {
		setType('');
		dispatch(fetchDeactivateService({id: service.id})).then(() => onUpdate());
	}, [onUpdate, service, dispatch]);

	const recoverItem = useCallback(() => {
		setType('');
		dispatch(fetchRecoverService({id: service.id})).then(() => onUpdate());
	}, [onUpdate, service, dispatch]);
	return (
		<SC.ButtonGroup>
			<ButtonCard title={'Изменить'} background={'#1C8666'} buttonClick={updateCard}/>
			{service.active &&
				<SC.ButtonWrapper>
					<ButtonCard buttonClick={warningRecoverService} title={'Деактивировать'} background={'#352C8D'}/>
					{type === 'recover' &&
						<ConfirmDeletion type={type} remove deleteFalse={() => setType('')} deleteTrue={deactivateItem}
										 style={SC.ConfirmDeletion}/>}
				</SC.ButtonWrapper>}
			{!service.active &&
				<>
					<ButtonCard buttonClick={recoverItem} title={'Активировать'}
								background={'rgb(28, 134, 102)'}/>
					<SC.ButtonWrapper>
						{type === 'remove' && <ConfirmDeletion type={type} remove deleteFalse={() => setType('')}
															   deleteTrue={deleteService}
															   style={SC.ConfirmDeletion}/>}
						<ButtonCard buttonClick={warningDeleteService} title={'Удалить'} background={'var(--yellow)'}/>
					</SC.ButtonWrapper>
				</>}
		</SC.ButtonGroup>
	);
};
