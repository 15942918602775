import styled from '@emotion/styled';

export const Label = styled.span`
	font-weight: 300;
	font-size: 14px;
	line-height: 1.3;
	text-wrap: nowrap;
`;
export const LocationWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;
export const DataWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	justify-content: flex-start;
	min-width: 190px;
`;
export const DataText = styled.span`
	font-weight: 600;
	font-size: 18px;
	text-wrap: nowrap;
`;
export const UnlimitedText = styled.div`
	font-weight: 600;
	font-size: 18px;
	color: var(--green);
`;
