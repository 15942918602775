import styled from '@emotion/styled';

export const HowOrder = styled.button`
  position: relative;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  background-color: transparent;
  gap: 6px;
  align-items: center;
  padding: 0;
`;
export const HowOrderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 260px;
  right: 0;
  bottom: -22px;
  @media screen and (max-width: 800px) {
    bottom: -30px;
  }
`;
export const List = styled.ol`
  position: absolute;
  font-size: 16px;
  list-style-type: decimal;
  list-style-position: inside;
  background-color: var(--border-input);
  z-index: 9999;
  width: 280px;
  padding: 10px;
  border-radius: 6px;
  right: 0;
  top: 110%;
`;
