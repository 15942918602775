import {createAsyncThunk} from '@reduxjs/toolkit';

import {SearchPlatformAPI} from '../../../api/search-platform-api';
import {ListAbonementsParams} from '../../../interfaces/abonement';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setShowLoader} from '../../reducers/user-data-reducer';


export const fetchGetTotalListAbonements = createAsyncThunk(
	'sportSlice/fetchGetTotalListAbonements',
		async (arg:ListAbonementsParams, {dispatch, rejectWithValue}) => {
			try {
				dispatch(setShowLoader({isShowLoader: true}));
				const response = await SearchPlatformAPI.getTotalListAbonements(arg);
				dispatch(setShowLoader({isShowLoader: false}));

				if (response.status === 200) {
					return {status: response.status, data: response.data.total};
				}
				if (response.status === 204) {
					return {status: response.status, data: 0};
				}
			} catch (error) {
				handleAxiosError({dispatch,error,rejectWithValue});
			}
		},
);
