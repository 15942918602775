import {FC, useCallback, useRef} from 'react';
import {useNavigate} from 'react-router-dom';

import {ServiceListItem} from '../../../interfaces/service';
import {useAppDispatch} from '../../../redux/store';
import {fetchGetServiceById} from '../../../redux/thunks/services/get-service-by-id';
import {UPDATE_SERVICE} from '../../../shared/constants/path';
import {ButtonGroupSettingService} from '../../buttons';
import {OtherServices} from '../card-service-new/cards/other-services/other-services';
import * as SC from './card-service.style';

interface CardServiceProps {
	service: ServiceListItem;
	onUpdate: () => void;
}

export const CardService: FC<CardServiceProps> = ({service, onUpdate}) => {
	// const [isOpenDeleteWindowType, setIsOpenDeleteWindowType] = useState('');
	const navigate = useNavigate();
	const elementRef = useRef<HTMLDivElement>(null);
	// useModalWindow(elementRef, () => setIsOpenDeleteWindowType(''));
	const dispatch = useAppDispatch();

	const updateCard = useCallback(async () => {
		await dispatch(fetchGetServiceById({id: service.id}));
		navigate(`/admin/services/${UPDATE_SERVICE}`);
	}, [service]);

	// TODO: remove OrganizationCardModal from here
	return (
		<SC.ServiceWrapper ref={elementRef}>
			<OtherServices key={service.id} service={service} isAdminPage/>
			<ButtonGroupSettingService service={service} onUpdate={onUpdate} updateCard={updateCard}/>
		</SC.ServiceWrapper>
	);
};

