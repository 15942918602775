import {FC, memo} from 'react';

import pencil from '../../../../../assets/icon/pencil-icon-edit.svg';
import {Button} from '../../../../../components';
import IconRemove from '../../../../../components/svg-icon-components/icon-remove';
import * as SC from './control-buttons.style';

interface Interface {
	bgColorButtonDelete?: 'yellow' | 'green' | 'purple' | 'white';
	clickUpdateAbonement: () => void;
	clickDeleteAbonement: () => void;
}

export const ControlButtons: FC<Interface> = memo(({
													   bgColorButtonDelete,
													   clickUpdateAbonement,
													   clickDeleteAbonement,
												   }) => {
	return (
		<SC.Container>
			<Button label={'Удалить'} style={SC.Button} bgColor={bgColorButtonDelete} onClick={clickDeleteAbonement}
					iconPosition={'left'} icon={<IconRemove />} />
			<Button label={'Изменить'} bgColor={'purple'} style={SC.Button} onClick={clickUpdateAbonement}
					iconPosition={'left'}
					icon={<img src={pencil} alt={'pencil'} />} />
		</SC.Container>
	);
});
