import styled from '@emotion/styled';

interface ContainerStyle {
	count: number;
}

export const Container = styled.section<ContainerStyle>`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(400px, ${props => props.count < 3 ? '400px' : '1fr'}));
	gap: 20px;
	width: 100%;
	@media screen and (max-width: 436px){
		grid-template-columns: repeat(auto-fit, 100%);
	}
`;
