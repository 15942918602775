import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	backgroundColor?: string;
	strokeColor?: string;
}

export const IconLock: FC<IconProps> = ({backgroundColor, strokeColor, ...restProps}) => {
	return (
		<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
			<rect width="22" height="22" rx="11" fill={backgroundColor} />
			<path
				d="M11.5 8.22876C11.5 7.95262 11.2761 7.72876 11 7.72876C10.7239 7.72876 10.5 7.95262 10.5 8.22876L11.5 8.22876ZM10.1611 9.76245C9.97011 9.56301 9.6536 9.55617 9.45417 9.74717C9.25473 9.93817 9.24789 10.2547 9.43889 10.4541L10.1611 9.76245ZM10.7 11.048L10.3389 11.3939L10.3389 11.3939L10.7 11.048ZM10.5 8.22876L10.5 10.9276H11.5L11.5 8.22876L10.5 8.22876ZM9.43889 10.4541L10.3389 11.3939L11.0611 10.7022L10.1611 9.76245L9.43889 10.4541ZM16.5 10.7348C16.5 13.9393 14.0174 16.4998 11 16.4998V17.4998C14.61 17.4998 17.5 14.4504 17.5 10.7348H16.5ZM11 16.4998C7.98258 16.4998 5.5 13.9393 5.5 10.7348H4.5C4.5 14.4504 7.39 17.4998 11 17.4998V16.4998ZM5.5 10.7348C5.5 7.53024 7.98258 4.96973 11 4.96973V3.96973C7.39 3.96973 4.5 7.01914 4.5 10.7348H5.5ZM11 4.96973C14.0174 4.96973 16.5 7.53024 16.5 10.7348H17.5C17.5 7.01914 14.61 3.96973 11 3.96973V4.96973ZM10.5 10.9276C10.5 10.6339 10.858 10.4901 11.0611 10.7022L10.3389 11.3939C10.7592 11.8328 11.5 11.5352 11.5 10.9276H10.5Z"
				fill={strokeColor}
			/>
		</svg>
	);
};

IconLock.defaultProps = {
	backgroundColor: '#1c8666',
	strokeColor: '#ffffff',
};
