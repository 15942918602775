import {createSlice} from '@reduxjs/toolkit';

import {OrderType} from '../../interfaces/enums';
import {Notification,WorkloadService,} from '../../interfaces/interfaces';
import {PlatformListItem, PlatformSingle} from '../../interfaces/platform';
import {ServiceListItem} from '../../interfaces/service';
import {fetchGetTotalServicesOrigins} from '../thunks/get-total-services-origins';
import {fetchGetNotificationsPlatform} from '../thunks/platform/get-notifications-platform';
import {fetchGetPlatformById} from '../thunks/platform/get-platform-by-id-thunk';
import {fetchGetPlatformsByFilter} from '../thunks/platform/get-platforms-by-filter';
import {fetchGetServicesPlatformFilter} from '../thunks/services/get-services-platform-filter';

export interface PlayFieldState {
	checkedPlatform: PlatformSingle | null;
	checkedPlatformUpdate: PlatformSingle | null;
	allPlatform: PlatformListItem[];
	servicesPlatformChecked: ServiceListItem[];
	servicesPlatform: ServiceListItem[];
	workloadService: WorkloadService[];
	servicesTotal: number;
	totalServicesOrigins: number;
	totalServicesDepsAllowed: number;
	servicesTotalDeps: number
	platformsTotal: number;
	notification: Notification | null;
	recommendations: ServiceListItem[];
	isCheckServiceId: { id: string, allow: OrderType } | null
	platform : PlatformListItem[]
}

const initialState: PlayFieldState = {
	checkedPlatformUpdate: null,
	allPlatform: [],
	checkedPlatform: null,
	notification: null,
	servicesPlatformChecked: [],
	servicesPlatform:[],
	workloadService: [],
	servicesTotal: 0,
	totalServicesOrigins: 0,
	totalServicesDepsAllowed: 0,
	servicesTotalDeps: 0,
	platformsTotal: 0,
	recommendations: [],
	isCheckServiceId: null,
	platform: [],
};

export const fieldSlice = createSlice({
	name: 'fieldSlice',
	initialState,
	reducers: {
		setRecommendations: (state: PlayFieldState, action) => {
			state.recommendations = [...action.payload.data];
		},
		setServicesPlatformChecked: (state: PlayFieldState, action) => {
			state.servicesPlatformChecked = [...action.payload.data];
		},
		setCheckedPlatform: (state: PlayFieldState, action) => {
			state.checkedPlatform = action.payload.data;
		},
		setAllPlatforms: (state: PlayFieldState, action) => {
			state.allPlatform = action.payload.data;
		},
		setWorkload: (state: PlayFieldState, action) => {
			state.workloadService = [...action.payload.data];
		},
		setServicesTotal: (state: PlayFieldState, action) => {
			state.servicesTotal = action.payload.data;
		},
		setServicesTotalDeps: (state: PlayFieldState, action) => {
			state.servicesTotalDeps = action.payload.data;
		},
		setPlatformsTotal: (state: PlayFieldState, action) => {
			state.platformsTotal = action.payload.data;
		},
		setTotalServicesDepsAllowed: (state: PlayFieldState, action) => {
			state.totalServicesDepsAllowed = action.payload;
		},
		setIsCheckServiceId: (state: PlayFieldState, action) => {
			state.isCheckServiceId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchGetTotalServicesOrigins.fulfilled, (state, action) => {
			if (action.payload?.total !== undefined) {
				state.totalServicesOrigins = action.payload.total;
			}
		});
		builder.addCase(fetchGetPlatformById.fulfilled, (state, action) => {
			state.checkedPlatformUpdate = action.payload?.data;
		});
		builder.addCase(fetchGetServicesPlatformFilter.fulfilled, (state, action) => {
			state.servicesPlatform = action.payload?.data;
		});
		builder.addCase(fetchGetNotificationsPlatform.fulfilled, (state, action) => {
			state.notification = action.payload?.data;
		});
		builder.addCase(fetchGetPlatformsByFilter.fulfilled, (state, action) => {
			state.platform = action.payload?.data;
		});
	},
});

export const {
	setIsCheckServiceId,
	setWorkload,
	setServicesPlatformChecked,
	setCheckedPlatform,
	setAllPlatforms,
	setServicesTotalDeps,
	setTotalServicesDepsAllowed,
	setServicesTotal,
	setPlatformsTotal,
	setRecommendations,
} = fieldSlice.actions;
