import {createSlice} from '@reduxjs/toolkit';

import {Stats} from '../../interfaces/interfaces';
import {fetchStatistic} from '../thunks/stats/statistic';

interface InitialStats {
	stats: Stats
}

const initialState: InitialStats = {
	stats: {
		orders_count: 0,
		payments_count: 0,
		abonements_count: 0,
		sales_volume: 0,
	}
};

export const statsSlice = createSlice({
	name: 'stats',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchStatistic.fulfilled, (state, action) => {
			state.stats = action.payload?.data;
		});
	}
});
export const stats = statsSlice.reducer;
