import axios from 'axios';

import {ListAbonementsParams} from '../interfaces/abonement';
import {
	HOST_SEARCH_ENGINE, urlGetAllCity, urlGetAllowsServices, urlGetAllPlatforms, urlGetAllSport,
	urlGetAllTypesService, urlGetImg, urlGetOrganizationByName, urlGetOrganizationNamesByFilter,
	urlGetOrganizationsByFilter, urlGetOrganizationsTotalByFilter, urlGetPlatformById, urlGetPlatformsByFilter,
	urlGetPlatformsTotalByFilter, urlGetServiceRecommendations, urlGetServicesByFilter, urlGetServicesTotalByFilter,
	urlListAbonements, urlServicesByFilterAllowedDeps, urlServicesByFilterOriginsDeps, urlServicesByFilterParams,
	urlServicesByFilterParamsDeps, urlServicesDependency, urlServicesDependencyAllowed, urlSingleService,
	urlTotalListAbonements,
} from '../shared/constants/url';
import {
	Allows_Service_Type_Params, AllServiceTypeParams, GetOrganizationNamesByFiltersParams,
	GetOrganizationsByFiltersParams, GetOrganizationsTotalByFilterParams, GetPlatformsByFilterParams,
	GetPlatformsTotalByFilterParams, GetServicesByFilterParams, GetServicesTotalByFilterParams,
	GetSportTypesByFilterParams, IUserListRecommendations,
} from './query-params';

const instance = axios.create({
	baseURL: HOST_SEARCH_ENGINE,
});

export const SearchPlatformAPI = {
	getAllPlatforms(params?: GetPlatformsByFilterParams) {
		return instance.get(urlGetAllPlatforms, {params});
	},
	getPlatformById(id: string) {
		return instance.get(`${urlGetPlatformById}/${id}`);
	},
	getPlatformsByFilter(params?: GetPlatformsByFilterParams) {
		return instance.get(urlGetPlatformsByFilter, {params});
	},
	getServicesByFilter(params: GetServicesByFilterParams) {
		return instance.get(urlGetServicesByFilter, {params});
	},
	getServiceById({id}: { id: string }) {
		return instance.get(urlSingleService + id);
	},
	getAllServicesOrigins(params: GetServicesByFilterParams) {
		return instance.get(urlServicesByFilterParams, {params});
	},
	getServicesDependency(id: string, params: GetServicesByFilterParams) {
		return instance.get(`${urlServicesDependency}${id}`, {params});
	},
	getServicesDependencyAllowed(id: string, params: GetServicesByFilterParams) {
		return instance.get(`${urlServicesDependencyAllowed}${id}`, {params});
	},
	getTotalServicesDepsAllowed({id, params}: { id: string, params: GetServicesByFilterParams }) {
		return instance.get(`${urlServicesByFilterAllowedDeps}${id}`, {params});
	},
	urlServicesByFilterOriginsDeps(params: GetServicesByFilterParams) {
		return instance.get(`${urlServicesByFilterOriginsDeps}`, {params});
	},
	getOrganizationsByFilter(params: GetOrganizationsByFiltersParams) {
		return instance.get(urlGetOrganizationsByFilter, {params});
	},
	getOrganizationNamesByFilter(params: GetOrganizationNamesByFiltersParams) {
		return instance.get(urlGetOrganizationNamesByFilter, {params});
	},
	getOrganizationByName(params: {org_name: string}) {
		return instance.get(urlGetOrganizationByName, {params});
	},
	getAllSports(params?: GetSportTypesByFilterParams) {
		return instance.get(urlGetAllSport, {params});
	},
	getAllTypesServices(params?: AllServiceTypeParams) {
		return instance.get(urlGetAllTypesService, {params});
	},
	getAllowsServices(paramsItem: Allows_Service_Type_Params) {
		return instance.get(urlGetAllowsServices, {params: {...paramsItem}});
	},
	getListRecommendations(params: IUserListRecommendations) {
		return instance.get(urlGetServiceRecommendations, {params});
	},
	getImg(issuer: string, issuer_id: string) {
		return instance.get(`${urlGetImg}${issuer}/${issuer_id}`);
	},
	getCities() {
		return instance.get(urlGetAllCity);
	},
	getOrganizationsTotalByFilter(params: GetOrganizationsTotalByFilterParams) {
		return instance.get(urlGetOrganizationsTotalByFilter, {params});
	},
	getServicesTotalByFilter(params?: GetServicesTotalByFilterParams) {
		return instance.get(urlGetServicesTotalByFilter, {params});
	},
	getServicesTotalByDeps(id: string) {
		return instance.get(`${urlServicesByFilterParamsDeps}${id}`);
	},
	getPlatformsTotalByFilter(params?: GetPlatformsTotalByFilterParams) {
		return instance.get(urlGetPlatformsTotalByFilter, {params});
	},
	getListAbonements(params?: ListAbonementsParams) {
		return instance.get(urlListAbonements, {params});
	},
	getTotalListAbonements(params?: ListAbonementsParams) {
		return instance.get(urlTotalListAbonements, {params});
	},
};
